<template>
    <div class="modal modal-blur" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <form>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Загрузка PDF файлов со срезами</h5>
            <p @click="hide()" title="Закрыть (Esc)">
              <a type="button" class="btn-close"></a>
            </p>
          </div>
          <div class="modal-body">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="mb-3">
                            <div class="form-label">Конвейерный штрек</div>
                            <input type="file" class="form-control" accept=".pdf" @change="uploadFile('konv')" ref="pdf_file_konv">
                        </div>

                        <hr class="mb-3" />

                        <div class="mb-3">
                            <div class="form-label">Вентиляционный штрек</div>
                            <input type="file" class="form-control" accept=".pdf" @change="uploadFile('vent')" ref="pdf_file_vent">
                        </div>

                        <hr class="mb-3" />

                        <div class="mb-3">
                            <div class="form-label">По лаве</div>
                            <input type="file" class="form-control" accept=".pdf" @change="uploadFile('lava')" ref="pdf_file_lava">
                        </div>
                    </div>
                </div>
            </div>
          </div>
  
          <div class="modal-footer">
              <button type="reset" class="btn">Очистить форму</button>
              <a class="btn" @click="hide()"><i class="fa fa-times"></i> Закрыть </a>
          </div>
        </div>
        </form>
      </div>
    </div>
  </template>
  
  
  <script>
  
  export default {
    name: "PdfuploadForm",
    props: {
        current_row: Number,
    },
    data: function () {
      return {
      };
    },
    mounted() {
    },
    methods: {
      hide(){
        this.$emit("hidePdfuploadForm")
      },
      async uploadFile(cut_type){
        let 
          formData = new FormData(),
          results_id = this.$route.params.id
        if(cut_type=="lava"){
            formData.append('image', this.$refs.pdf_file_lava.files[0])
        } else if (cut_type=="vent"){
            formData.append('image', this.$refs.pdf_file_vent.files[0])
        } else if (cut_type=="konv"){
            formData.append('image', this.$refs.pdf_file_konv.files[0])
        }
        formData.append('cut_type', cut_type)
        formData.append('calculation', results_id)
        formData.append('row', this.current_row)
        const response = await fetch('/api/pdf-upload/', {
            method: 'POST',
            body: formData,
            credentials: "include",
        })
        if (response.ok) {
            let results = await response.json()
            const routeData = this.$router.resolve({ name: cut_type, params: { id: results.id } })
            window.open(routeData.href, '_blank');
        }
      },

    },
  };
  </script>
  
  <style>
    #correction_modal .card-header {padding: 3px 12px;}
    #correction_modal input.form-control {padding: 2px 5px;}
    #correction_modal .table td {padding: 1px 8px}
    #correction_modal .modal-body {padding: 5px 10px;}
  </style>
