<template>
  <ul class="navbar-nav">
    <li class="nav-item" v-bind:class="{ active: $route.name == 'new' }">
      <a href="/" class="nav-link">
        <span class="nav-link-icon d-md-none d-lg-inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="4" y="3" width="16" height="18" rx="2" /><rect x="8" y="7" width="8" height="3" rx="1" /><line x1="8" y1="14" x2="8" y2="14.01" /><line x1="12" y1="14" x2="12" y2="14.01" /><line x1="16" y1="14" x2="16" y2="14.01" /><line x1="8" y1="17" x2="8" y2="17.01" /><line x1="12" y1="17" x2="12" y2="17.01" /><line x1="16" y1="17" x2="16" y2="17.01" /></svg>
        </span>
        <span class="nav-link-title"> Новый рассчёт</span>
      </a>
    </li>
    <li class="nav-item" v-bind:class="{ active: $route.name == 'reports' }" v-if="false">
      <router-link :to="{ name: 'reports' }" class="nav-link">
        <span class="nav-link-icon d-md-none d-lg-inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline points="12 8 12 12 14 14" /><path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" /></svg>
        </span>
        <span class="nav-link-title"> История рассчётов</span>
      </router-link>
    </li>
  </ul>
</template>




<script>
export default {
  name: "Topmenu",
  data: () => ({
    is_superuser: localStorage.getItem("is_superuser") == 'true',
  }),
  props: {},
};
</script>