<template>
  <div class="card-body">
    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">Амплитуда, H</label>
      <div class="col">
        <div class="input-group">
          <input type="number" step="0.01" class="form-control text-end" v-model="form.H">
          <span class="input-group-text unit">
            м
          </span>
        </div>
      </div>
    </div>
    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">Угол встречи, φ</label>
      <div class="col">
        <div class="input-group">
          <input type="number" class="form-control text-end" v-model="form.phi">
          <span class="input-group-text unit">
            º
          </span>
        </div>
      </div>
    </div>
    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">Угол падения сместителя</label>
      <div class="col">
        <div class="input-group">
          <input type="number" class="form-control text-end" v-model="form.delta">
          <span class="input-group-text unit">
            º
          </span>
        </div>
      </div>
    </div>
    <div class="form-group mb-3 row">
      <label class="form-label col-7 col-form-label">Мощность пласта</label>
      <div class="col">
        <div class="input-group">
          <input type="number" class="form-control text-end" v-model="form.M" @change="() => { if(form.M > 10 ) { form.M = 10 }}">
          <span class="input-group-text unit">
            м
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: Object,
  },
};
</script>

