<template>
  <layout>
    <input type="range" class="form-range mb-2" value="40" min="10" max="100" step="1" v-model="pixcel" @input="draw_swg()">
    <div style="background-position: center; background-repeat: no-repeat; background-size: cover;" v-bind:style="{'background-image': png_path,'width':png_width,'height':png_height}">
      <svg id="svg-side-view" :width="total_width" :height="total_height" style="cursor: move;">
        <g id="curves" :width="total_width" :height="total_height">
          <path :d="L1" stroke="#f00" fill="none" stroke-width="3"></path>
          <path :d="L1_blue" stroke="#00f" fill="none" stroke-width="3"></path>
          <path :d="L3" stroke="#f00" fill="none" stroke-width="6"></path>
          <path :d="L3_check" stroke="#ff0" fill="none" stroke-width="6"></path>
          <path :d="L2" stroke="#f00" fill="none" stroke-width="3"></path>
          <path :d="L2_blue" stroke="#00f" fill="none" stroke-width="3"></path>
          <g id="ruler">
            <line x1="2" y1="15" :x2="total_width+start_x+2" y2="15" stroke="#666" stroke-width="2"></line>
            <line :x1="x+2" y1="10" :x2="x+2" y2="20" stroke="#666" stroke-width="1" v-for="x in ruler_lines" v-bind:key="x"></line>
            <text
              :x="x"
              :y="8"
              style="font-size:8px;"
              v-for="(x, i) in ruler_lines" v-bind:key="x+'q'"
            >
              {{ i }} м
            </text>
          </g>
        </g>
      </svg>
    </div>
  </layout>
</template>

<script>
import Layout from "../../layouts/Empty.vue";
import Snap from "snapsvg-cjs";


export default {
  components: {
    Layout
  },
  data: function () {
    return {
      png_path: "",
      png_width: "0",
      png_height: "0",
      results: {},
      input_data: {},
      L1: "",
      L1_blue: "",
      L2: "",
      L2_blue: "",
      L3: "",
      L3_check: "",
      border_thickness: 20,
      start_x: 0,
      pixcel: 50,
      total_width: 0,
      total_height: 0,
      ruler_lines: [],
    }
  },
  methods: {
    load_results(){
      fetch(`/api/pdf/${this.$route.params.id}/`)
      .then((response) => response.json())
      .then((results) => {

        this.png_path = `url(${results.png_path})`
        this.png_width = `${results.png_width}px`
        this.png_height = `${results.png_height}px`

        if(results.cut_type == "vent"){
          this.results = results.calculation_results.results_vent
          this.input_data = results.calculation_results.input_data_vent
        } else if (results.cut_type == "konv"){
          this.results = results.calculation_results.results_konv
          this.input_data = results.calculation_results.input_data_konv
        }
        this.draw_swg()
      })
    },
  draw_swg(){
    let 
      svg = Snap("#svg-side-view"),
      move = function(dx,dy) {
        this.attr({
          transform: this.data('origTransform') + (this.data('origTransform') ? "T" : "t") + [dx, dy]
        });
      },
      start = function() {this.data('origTransform', this.transform().local )}

      this.pixcel = parseInt(this.pixcel)

      let
        alpha_ = this.results["alpha_"],

      // длины участков нарушения
      Lpad1 = this.results["Lpr1"] * this.pixcel,
      h1 = this.results["h1"] * this.pixcel,
      Lpad2 = this.results["Lpr2"] * this.pixcel,
      h2 = this.results["h2"] * this.pixcel,
      Lpad3 = this.results["Lpr3"] * this.pixcel,
      h3 = Lpad3 * Math.sin(alpha_ * Math.PI/180),

      // радиусы кривизны кривых
      RL1 = Lpad1 / (2 * Math.sin(0.5 * alpha_ * Math.PI/180)),
      RL1_concavity = 1,
      RL2 = Lpad2 / (2 * Math.sin(0.5 * alpha_ * Math.PI/180)),
      RL2_concavity = 0,

      // спуск или подъём
      violation_type = this.results["violation_type"],
      direction = this.results["direction"],
      left_part_lower = (violation_type == "asecent" && direction == "from_left") || (violation_type == "descent" && direction == "from_right"),


      point1 = {x: this.start_x, y: this.border_thickness},
      point2 = {x: point1.x+Lpad1, y: point1.y+h1-5},
      point3 = {x: point2.x+Lpad3*Math.cos(alpha_ * Math.PI/180), y: point2.y+h3+3},
      point4 = {x: point3.x+Lpad2, y: point3.y+h2+7}

      if(left_part_lower){
        point1 = {x: this.start_x, y: this.border_thickness + (this.input_data.M + this.input_data.H) * this.pixcel}
        point2 = {x: point1.x+Lpad1, y: point1.y-h1}
        point3 = {x: point2.x+Lpad3 * Math.cos(alpha_ * Math.PI/180), y: point2.y-h3}
        point4 = {x: point3.x+Lpad2, y: point3.y-h2-this.border_thickness}
        RL1_concavity = 0
        RL2_concavity = 1
      }

      this.L1 = `M ${point1.x} ${point1.y} A ${RL1} ${RL1} 90 0 ${RL1_concavity} ${point2.x} ${point2.y}`
      this.L1_blue = `M ${point1.x} ${point1.y+3} A ${RL1} ${RL1} 90 0 ${RL1_concavity} ${point2.x} ${point2.y+3}`
      this.L2 = `M ${point3.x} ${point3.y+3} A ${RL2} ${RL2} 90 0 ${RL2_concavity} ${point4.x} ${point4.y+3}`
      this.L2_blue = `M ${point3.x} ${point3.y} A ${RL2} ${RL2} 90 0 ${RL2_concavity} ${point4.x} ${point4.y}`
      this.L3 = `M ${point2.x} ${point2.y+2} ${point3.x} ${point3.y+2}`

      this.total_width = Lpad1 + Lpad2 + Lpad3
      this.total_height = h1 + h2 + h3 + this.border_thickness + 10
      this.ruler_lines = Array()
      for(let i=0;i<=this.total_width;i+=this.pixcel){
        this.ruler_lines.push(i)
      }
      svg.drag(move, start)
  }
  },
  mounted() {
    this.load_results()
  },
};
</script>

