<template>
  <div style="width:100%;height: 600px;" :style="{ 'margin-top': marginTop + 'px' }">
    <svg id="svg-side-view" style="width: 100%;height: 400px;transform-origin: 0 0;">
      <defs>
        <pattern id="stroke-pattern" x="0" y="0" width="10" height="10" patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="10" height="10" fill="#999" />
          <path d="M10-5-10,15M15,0,0,15M0-5-20,15" stroke="#333" />
        </pattern>
      </defs>

      <Lava :H="corrected_data.H" :M="corrected_data.M" :pixcel="this.pixcel" :border_thickness="this.border_thickness" v-if="show_lava"/>
      <Coal :input_data="corrected_data" :results="this.results" :params="calculate_results" :pixcel="this.pixcel" :border_thickness="this.border_thickness" v-if="show_coal" :row="this.row" />
      <Support 
        :point="support.point"
        :alpha="support.alpha"
        :height="support.height"
        :width="support.width"
        :num="support.num"
        :part="support.part"
        v-for="support in supports" :key="support.num"
       />
      <Ruler :input_data="this.input_data" :pixcel="this.pixcel" :border_thickness="this.border_thickness" v-if="show_ruler"></Ruler>
    </svg>
  </div>
</template>

<script>
import Snap from "snapsvg-cjs";

import Lava from "./Lava.vue";
import Coal from "./Coal.vue";
import Support from "./Support.vue";
import Ruler from "./Ruler.vue";


export default {
  components: {
    Lava, Coal, Support, Ruler
  },

  props: {
    calculate_results: Object,
    // текущий номер цикла
    row: Number, 
    first_section_in_violation: Number,
    first_section_out_violation: Number,
    // общее количество циклов
    total_rows: Number,

    // коррекции
    corrections: Array,
    violation: Object,
    show_coal: Boolean,
    show_lava: Boolean,
    show_ruler: Boolean,
  },
  data: function () {
    let
      pixcel = 20,
      border_thickness = 10,
      calculate_results = JSON.parse(JSON.stringify(this.calculate_results)),
      input_data = JSON.parse(JSON.stringify(calculate_results))
    return {
      svg: undefined,
      pixcel: pixcel, // сколько пикселей в метре
      input_data: input_data,
      corrected_data: calculate_results.input_data_konv, // данные скорректированные по конкретному срезу
      results: calculate_results.results_konv,
      results_vent: calculate_results.vent,
      border_thickness: border_thickness, // отступы сверху и снизу
      supports: [],
      marginTop: 0
    }
  },
  watch: { 
    calculate_results: function(){
      this.draw_svg()
      this.rotate_svg()
    },
    row: function(){
      this.draw_svg()
    },
    corrections: {
      handler: function () {
        this.draw_svg()
      },
      deep: true
    },
    violation: {
      handler: function () {
        this.draw_svg()
      },
      deep: true
    },
  },
  mounted(){
    this.draw_svg()
    this.rotate_svg()
  },
  methods: {
    draw_svg(){    
      this.svg = Snap("#svg-side-view");
      this.input_data = this.calculate_results.input_data_konv
      if(!this.input_data) return

      this.supports = []

      if(this.calculate_results.support_params){
        let 
          support_params_data = this.calculate_results.support_params,
          current_row_data = support_params_data[this.row],
          l = parseFloat(this.input_data.l),
          h_krepi = parseFloat(this.input_data.h_krepi),
          support_height = h_krepi * this.pixcel -1,
          support_width = l * this.pixcel,
          x = 0

          if(current_row_data) current_row_data.forEach(support => {
            this.supports.push({
              num:support.num,
              point: {x: x, y: support.y * this.pixcel + this.border_thickness + 1},
              width: support_width,
              height: support_height,
              alpha: support.angle,
              part: support.part,
            })
            x += support.x * this.pixcel
          })
      }

    },
    rotate_svg(){
      let
       gamma = this.calculate_results.input_data_konv.gamma,
       direction = this.input_data.direction
      if(direction == "from_right") gamma = -gamma
      this.svg.transform(`r${gamma} 0,0`)
      if(gamma < 0)
        this.marginTop = -gamma * 35

    },
  },
};
</script>
